import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from "./Header";
import RoomDetails from "./RoomDetailsPage"; // Import RoomDetails component
import Footer from './Footer';
// import { useNavigate } from "react-router-dom";

const OtpSender = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [error, setError] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');
  const [otpMessage, setOtpMessage] = useState('');
  const [roomDetails, setRoomDetails] = useState(null); // State to store room details
  // const navigate = useNavigate();

  const handleNumberInput = (e) => {
    const inputValue = e.target.value;

    // Remove non-numeric characters from the input
    const numericValue = inputValue.replace(/[^0-9]/g, '');

    if (numericValue === '') {
      // Handle the case where the input is empty (user deleted all digits)
      setPhoneNumber('');
    } else if (/^[6-9]\d{0,9}$/.test(numericValue)) {
      // Update the state with the cleaned numeric value
      setPhoneNumber(numericValue);
    } else {
      // Handle the case where the input does not start with 6-9
      // You can show an error message or take other actions
      // setError('Invalid input. Please enter a valid mobile number starting with 6-9.');
      setTimeout(() => {
        setError('');
      }, 5000); // Clear error message after 5 seconds
    }
  };

  const sendOtp = async () => {
    if (phoneNumber.trim() === '') {
      setError('Please enter your mobile number.');
      setTimeout(() => {
        setError('');
      }, 4000);
      return;
    }
  
    try {
      const response = await axios.post('http://localhost:3001/sendotp', { number: phoneNumber });
      console.log(response.data);
      setOtpSent(true);
      setOtpMessage('OTP sent successfully. Please check your phone.');
      setTimeout(() => {
        setOtpMessage('');
      }, 5000); // Clear otpMessage after 5 seconds
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('This number does not exist in booking'); // Set custom error message
      } else {
        setError('Failed to send OTP. Please try again later.');
        console.error(error);
      }
      setTimeout(() => {
        setError('');
      }, 5000); // Clear error message after 5 seconds
    }
  };
  
  const resendOtp = async () => {
    try {
      await sendOtp();
    } catch (error) {
      setError('Failed to resend OTP. Please try again later.');
      console.error(error);
      setTimeout(() => {
        setError('');
      }, 5000); // Clear error message after 5 seconds
    }
  };

  const verifyOtp = async () => {
    if (otp.trim() === '') {
      setVerificationStatus('Please enter the OTP.');
      setTimeout(() => {
        setVerificationStatus('');
      }, 4000); // Clear error message after 4 seconds
      return;
    }
  
  try {
  const response = await axios.post('http://localhost:3001/verifyotp', { number: phoneNumber, otp });
  console.log(response.data);
  if (response.status === 200) {
    // Display SweetAlert for successful OTP verification
    Swal.fire({
      icon: 'success',
      title: 'OTP Verified!',
      text: 'You have successfully verified the OTP.',
      timer:4000,
    }).then((result) => {
      // If the user clicks "OK" on the SweetAlert dialog
      if (result.isConfirmed) {
        // Set the verification status
        setVerificationStatus('OTP verified successfully');
        // Fetch and set room details after OTP verification
        setRoomDetails(response.data);
        // Log room details
        console.log('Room details:', response.data);
      }
    });
  }
} catch (error) {
  // If OTP verification fails, set the verification status and log the error
  setVerificationStatus('OTP is incorrect');
  console.error(error);
}
  };

  return (
  <div>
    <Header />
    {roomDetails ? (
      // Render room details if available
      <RoomDetails roomDetails={roomDetails} />
    ) : (
      // Render OTP form if room details not available
      <div style={styles.container} className='mt-5'>
        <h3 style={styles.heading}>Verify Your Number</h3>
        <input
          type="text"
          name="number"
          placeholder="Enter phone number"
          value={phoneNumber}
          onChange={handleNumberInput}
          style={styles.input}
          required
        />
        {!otpSent ? (
          <div>
            {error && <p style={styles.error}>{error}</p>}
            <button onClick={sendOtp} style={styles.button}>
              Send OTP
            </button>
          </div>
        ) : (
          <div>
            
            <p>{otpMessage}</p>
            <button onClick={resendOtp} style={styles.button}>
              Resend OTP
            </button>
            {error && <p style={styles.error}>{error}</p>}
            {verificationStatus && <p style={styles.success}>{verificationStatus}</p>}
          </div>
        )}
        {otpSent && (
          <div>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              style={styles.input}
            />
            <button onClick={verifyOtp} style={styles.button}>
              Verify OTP
            </button>
          </div>
        )}
      </div>
    )}
    <div style={{marginTop:'13%'}}><Footer /></div>
  </div>

);
};

const styles = {
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  heading: {
    marginBottom: '20px',
    color: '#333',
  },
  input: {
    width: '85%',
    padding: '10px',
    marginBottom: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  button: {
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    cursor: 'pointer',
    marginBottom: '20px',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
  success: {
    color: 'green',
    marginTop: '10px',
  },
};

export default OtpSender;